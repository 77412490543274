var generic = generic || {};
var site = site || {};

site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function ($) {
  site.emailSignup = {
    templateContainer: $('.site-email-signup'),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var deferredObjects = [];
        // Lets check the settings to get the INTEREST SECTION INPUT NAMES which should be used to display the User Interest Options (different between locales).
        var interestSectionInputNames =
          Drupal.settings.common.interest_sections_input_names || 'PERFUME_AS_AN_ART,KILIAN_MY_KIND_OF_LOVE';
        var attrNames = interestSectionInputNames.trim().split(',');

        // WHY IS THIS HERE? IT SHOULD BE MOVED.
        $('footer').parent().addClass('millennials-footer-update');

        // Show terms + conditions for mobile only
        // $([$emailInput, $mobileNumberInput]).on('focus', function(e) {
        $emailInput.add($mobileNumberInput).on('focus', function (e) {
          $(this).parent().next().addClass('site-email-signup__terms-conditions--active');
        });

        // Pass Multiple arguments in rpc to fetch values
        $.each(attrNames, function () {
          var attrName = this;
          var thisDefer = $.Deferred();

          deferredObjects.push(thisDefer);
          site.onLoadRpc.requests.push({
            method: 'user.getAttOption',
            params: [
              {
                attribute_name: attrName,
                select_type: 'checked',
                _TOKEN: $.cookie('csrftoken')
              }
            ],
            onSuccess: function (data) {
              thisDefer.resolve(data.result.value);
            },
            onError: function () {}
          });
        });

        $.when.apply($, deferredObjects).then(function (data1, data2) {
          var checkboxData = data1 && data2 ? $.merge($.merge([], data1), data2) : data1;

          if (checkboxData !== null && checkboxData.length > 0) {
            var $jsMostIntersted = $('.select_interest', $emailContainerNode);

            $.each(checkboxData, function (ele, index) {
              var checkboxContainer = $('<div/>').addClass('teaser-check').appendTo($jsMostIntersted);

              $('<input/>')
                .val(index.value)
                .attr({
                  type: 'checkbox',
                  name: attrNames[ele]
                })
                .appendTo(checkboxContainer);
              $('<label/>').text(index.label).appendTo(checkboxContainer);
            });
          }
        });

        // var $userCheck =  $('.teaser-check input[type=checkbox]');
        $(document).on('change', '.teaser-check input[type=checkbox]', function () {
          $('.teaser-check input[type="checkbox"]').not(this).prop('checked', false);
        });

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
            params.PC_EMAIL_PROMOTIONS = 1;
          }

          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          if (
            !$emailContainerNode.find('input[name="FIRST_NAME"]').length &&
            !$emailContainerNode.find('input[name="LAST_NAME"]').length
          ) {
            params.NONAMES = 1;
          }

          $emailContainerNode.addClass('loading');

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                // Close active utility items
                $('.js-gnav-util__content').addClass('hidden');
                $emailInput.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                generic.overlay.launch({
                  height: 'auto',
                  html: $emailSuccess.html()
                });
              }

              $emailContainerNode.removeClass('loading');
            },
            onFailure: function () {
              $emailError.removeClass('hidden');
              $emailInput.addClass('error');
              $emailContainerNode.removeClass('loading');
            }
          });
        });
      });
    },
    gdprRemInfoMessageBox: function (context) {
      if ($('.info-msg-gdpr', context).get(0)) {
        $('.info-msg-gdpr', context).remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + infoMsgArrow.outerHeight(true);
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''));
      var infoMsgArrowMidPoint = infoMsgArrow.outerWidth(true) / 2;

      if (isMobile) {
        // This is the gap between popup and page in mobile. Ideal is 10px.
        var popupLeftPosMobile = 10;

        infoMsgArrow.css({
          left: infoLinkLeft - infoMsgArrowMidPoint + 'px'
        });
        infoLinkLeft = popupLeftPosMobile;
      } else if (infoMessageBox.outerWidth(true) === infoMsgMaxWidth && infoLinkLeft + infoMsgMaxWidth > screen.width) {
        infoLinkLeft = infoLinkLeft - infoMessageBox.innerWidth() + infoMsgArrowMidPoint;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.common.gdpr_compliant) {
        var isMobile = Unison.fetch.now().name === 'small';

        $('body', context).click(function (e) {
          if (e.target.className !== 'info-msg-gdpr' && e.target.className !== 'info-msg-gdpr__txt') {
            $('.info-msg-gdpr', context).addClass('hidden');
          }
        });
        $('.js-info-link-gdpr', context).live('click', function (e) {
          e.preventDefault();
          var infoLink = $(e.currentTarget);

          site.emailSignup.gdprRemInfoMessageBox(context);

          $(
            "<div class='info-msg-gdpr'><div class='info-msg-gdpr__arrow'></div><div class='info-msg-gdpr__txt js-info-msg-gdpr__txt'></div></div>"
          ).appendTo('body');
          var infoBoxEle = $('#' + infoLink.attr('message-in'), context);
          var infoMessageBox = $('.info-msg-gdpr', context);
          var infoMsgArrow = $('.info-msg-gdpr__arrow', context);

          $('.info-msg-gdpr__txt', context).html(
            "<div class='info-msg-gdpr__close js-info-msg-gdpr__close'>X</div>" + infoBoxEle.html()
          );

          $('#cboxClose, .js-info-msg-gdpr__close', context).live('click', function (e) {
            e.stopPropagation();
            site.emailSignup.gdprRemInfoMessageBox(context);
          });

          site.emailSignup.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
          $(window).bind(
            'resize-debounced',
            site.emailSignup.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile)
          );
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      site.emailSignup.gdprToolTip(context);
    }
  };
})(jQuery);
